import './index.css';

function App() {
  return (
    <div className="bg-primary-dark h-screen content-center space-y-2">
      <img src="/logo.png" alt="logo" width="150" height="auto" className="mx-auto" />
      <h2 className="text-white text-bold text-center text-2xl">Our website is under development.</h2>
      <p className="text-white text-center text-sm">Last Updated - 3 Aug 2024</p>
    </div>
  );
}

export default App;
